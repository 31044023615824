import gql from "graphql-tag";
export const LOAD_PAGINATION_INFO = gql`
  query {
    loadPagination @client {
      limit
      skip
    }
  }
`;
export const LOAD_PRODUCTS_TO_ADD = gql`
  query {
    productsChecked @client {
      products
    }
  }
`;
export const LOAD_PRODUCT_FILTER = gql`
  query {
    productFilter @client {
      myProducts
      category
    }
  }
`;
export const LOAD_REFUSE_REASON_TEXT = gql`
  query {
    refuseReason @client {
      text
    }
  }
`;

export const GET_TEMPLATE_PROJECT = gql`
  query {
    templateProject @client {
      templateProject
    }
  }
`;
