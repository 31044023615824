import jwtDecode from "jwt-decode";

class Token {
  token = null;

  constructor() {
    this.init();
  }

  init() {
    this.token = localStorage.getItem("token-suer");
  }

  getToken = () => this.token;

  setToken = (token) => (this.token = token);

  getId = () => {
    if (!this.token) {
      return null;
    } else {
      const { id } = jwtDecode(this.token);
      return id;
    }
  };
  getRole = () => {
    if (!this.token) {
      return null;
    } else {
      const { role } = jwtDecode(this.token);
      return role;
    }
  };
  getStoreId = () => {
    if (!this.token) {
      return null;
    }
    const { storeId } = jwtDecode(this.token);
    return storeId;
  };
}

export default new Token();
