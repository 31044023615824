import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "home",
    title: "home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["ADMIN"],
    navLink: "/",
  },
  {
    type: "groupHeader",
    groupTitle: "APPS",
  },
  {
    id: "projects",
    title: "projects",
    type: "item",
    icon: <Icon.ShoppingBag size={20} />,
    permissions: ["VIEW_PROJECTS"],
    navLink: "/projets",
  },
  {
    id: "theme",
    title: "theme",
    type: "item",
    icon: <Icon.Layers size={20} />,
    permissions: ["VIEW_THEME"],
    navLink: "/projets-theme",
  },

  {
    id: "labels",
    title: "Translation",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["VIEW_TRANSLATION_DASHBOARD"],
    navLink: "/settings/labels",
  },
  {
    id: "labels-mobile",
    title: "Translation mobile",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["VIEW_TRANSLATION_CLIENT"],
    navLink: "/settings/labels-mobile",
  },
  {
    id: "labels-mobile-pro",
    title: "Translation pro",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["VIEW_TRANSLATION_PRO"],
    navLink: "/settings/labels-pro",
  },
  {
    id: "deployProjects",
    title: "Deploiement",
    type: "item",
    icon: <Icon.GitPullRequest size={20} />,
    permissions: ["DEPLOY_PROJECTS"],
    navLink: "/projets/deployProjects",
  },
  {
    id: "default-settings",
    title: "Paramètre par default",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["VIEW_DEFAULT_SETTINGS"],
    navLink: "/settings/default-data",
  },
  {
    id: "version",
    title: "Gestion des nouvautés",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["VIEW_VERSION"],
    navLink: "/versions",
  },
  {
    id: "users",
    title: "Gestion des users",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["VIEW_USERS"],
    navLink: "/users",
  },
];

export default navigationConfig;
