const isLocal = false;
const PROTOCOL = isLocal ? "http://" : "https://";
const WS_PROTOCOL = isLocal ? "ws://" : "wss://";
const HOST = isLocal ? "localhost:2020" : "superapi.toolynk.fr";
const WS_ENDPOINT = "/subscriptions";
const GOOGLE_API_KEY = "AIzaSyDJqKQDJ5WtannWAwYWUbSPmNi5MDbaX48";
const STRIPE_ENDPOINT = "https://api.stripe.com/v1/tokens";
const STRIPE_DASHBORD = "https://dashboard.stripe.com/login";
const STRIPE_SECRET_KEY = "sk_test_X5KuJ3uuq3Ktjg3aQOjoLRdC00cPWoI5ts";
const STRIPE_PUPLIC_KEY = "pk_test_aZ6WqOrDWOMN8H5d3gjy0nyn00FAFiuUSj";
const BASE_URL = `${PROTOCOL}${HOST}`;
const PHOTO_URL = `${PROTOCOL}${HOST}`;
const WS_URL = `${WS_PROTOCOL}${HOST}${WS_ENDPOINT}`;
const DEFAULT_LANGAGE = "fr";
const ELASTICSEARCH_CONFIG = {
  url: "https://elastic.toolynk-lab.com",
  appBaseUrl: "https://scalr.api.appbase.io",
  appName: "lynkbooster-app",
  credentials: "BmRKaiexc:eb98f230-bf48-4a34-a146-61c65c1ce5a4",
};
const App = {
  name: "LynkBooster",
  logo: "https://lynkbooster.com/wp-content/uploads/2019/05/logo-full.png",
};
const LANGUAGES = [
  {
    flag: "fr",
    langage: "Français",
    lang: "fr",
  },
  {
    flag: "US",
    langage: "English",
    lang: "en",
  },
];
const DeliveryApiTypes = ["STUART", "YPER", "CUSTOM", "OTHERS"];
const PAYMENT_APIS = { stripe: "stripe", paypal: "paypal" };

export {
  BASE_URL,
  WS_URL,
  PHOTO_URL,
  GOOGLE_API_KEY,
  STRIPE_ENDPOINT,
  STRIPE_SECRET_KEY,
  STRIPE_PUPLIC_KEY,
  ELASTICSEARCH_CONFIG,
  App,
  DEFAULT_LANGAGE,
  LANGUAGES,
  STRIPE_DASHBORD,
  DeliveryApiTypes,
  PAYMENT_APIS,
};
