import { useQuery, useMutation } from "react-apollo";
import {
  ADD_TEMPLATE_PROJECT,
  DELETE_IMAGE_FROM_ARRAY,
  UPDATE_TEMPLATE_PROJECT,
  GET_PROJECT_TEMPLATE_BY_PROJECT_ID,
  GET_ALL_IMAGES_AS_ZIP,
  ADD_TEMPLATE_STORE,
  DUPLICATE_TEMPLATE_PROJECT,
  DELETE_IMAGE_FROM_TEMPLATE,
  CHANGE_IMAGE_IN_ARRAY,
} from "graphql/tags";
import { GET_TEMPLATE_PROJECT } from "graphql/query.local";
import { saveAs } from "file-saver";
import { BASE_URL } from "../../configs/constants";

export const useMutationAddTemplateProject = () => {
  const [addTemplateProject] = useMutation(ADD_TEMPLATE_PROJECT);
  const [addTemplateStore] = useMutation(ADD_TEMPLATE_STORE);

  function addTemplateProjectMutation({ input }) {
    addTemplateProject({
      variables: {
        input,
      },
    });
  }

  function addTemplateStoreMutation({ input }) {
    addTemplateStore({
      variables: {
        input,
      },
    });
  }

  return { addTemplateProjectMutation, addTemplateStoreMutation };
};

export const useMutationChangeImageInArray = () => {
  const [changeImageInArrayTemplateProject] = useMutation(
    CHANGE_IMAGE_IN_ARRAY
  );

  function changeImageInArrayTemplateProjectMutation(
    id,
    app,
    store,
    section,
    image,
    newImage
  ) {
    changeImageInArrayTemplateProject({
      variables: {
        id,
        app,
        store,
        section,
        image,
        newImage,
      },
    });
  }

  return {
    changeImageInArrayTemplateProjectMutation,
  };
};

export const useMutationDeleteImage = () => {
  const [deleteImageFromArrayTemplateProject] = useMutation(
    DELETE_IMAGE_FROM_ARRAY
  );
  const [deleteImageTemplateProject] = useMutation(DELETE_IMAGE_FROM_TEMPLATE);

  function deleteImageProjectFromArrayMutation(id, app, store, section, image) {
    deleteImageFromArrayTemplateProject({
      variables: {
        id,
        app,
        store,
        section,
        image,
      },
    });
  }

  function deleteImageTemplateProjectMutation(
    id,
    app,
    store,
    section,
    subSection
  ) {
    deleteImageTemplateProject({
      variables: {
        id,
        app,
        store,
        section,
        subSection,
      },
    });
  }

  return {
    deleteImageProjectFromArrayMutation,
    deleteImageTemplateProjectMutation,
  };
};

export const useMutationGetZipOfAllImages = () => {
  const [GetZipOfAllImages] = useMutation(GET_ALL_IMAGES_AS_ZIP);
  function getZipOfAllImagesMutation({ input, id, subProjects }) {
    GetZipOfAllImages({
      variables: {
        id,
        input,
        subProjects,
      },
      update: async (cache, { data }) => {
        saveAs(BASE_URL + data?.GetZipOfAllImages, id + ".zip");
      },
    });
  }
  return { getZipOfAllImagesMutation };
};

export const useMutationUpdateTemplateProject = () => {
  const [updateTemplateProject] = useMutation(UPDATE_TEMPLATE_PROJECT);
  function updateTemplateProjectMutation({ input, id, subProjects }) {
    updateTemplateProject({
      variables: {
        id,
        input,
        subProjects,
      },
    });
  }
  return { updateTemplateProjectMutation };
};

export const useMutationDuplicateTemplateProject = () => {
  const [duplicateTemplateProject] = useMutation(DUPLICATE_TEMPLATE_PROJECT);
  function duplicateTemplateProjectMutation({ id, duplicatedProjectName }) {
    duplicateTemplateProject({
      variables: {
        id,
        duplicatedProjectName,
      },
    });
  }
  return { duplicateTemplateProjectMutation };
};

export const useQueryGetTemplateProject = () => {
  const { data, loading, refetch } = useQuery(GET_TEMPLATE_PROJECT);
  return {
    data: data?.templateProject,
    loading,
    refetch,
  };
};

export const useQueryGetTemplateByProjectId = ({ project, subStore }) => {
  const { data, loading, refetch } = useQuery(
    GET_PROJECT_TEMPLATE_BY_PROJECT_ID,
    {
      variables: {
        project,
        subStore,
      },
    }
  );
  return {
    data: data?.projectTemplateByProjectId,
    loading,
    refetch,
  };
};
