import React from "react";
import { DEFAULT_LANGAGE } from "configs/constants";
import fr from "lang/fr.json";
import en from "lang/en.json";
import { useQueryMe } from "hooks";

const LangageContext = React.createContext();
const langage = { fr, en };
const langageLabels = {
  fr: "Français",
  en: "English",
};
const LangProvider = (props) => {
  const [lang, setLang] = React.useState(DEFAULT_LANGAGE);
  const { data } = useQueryMe();
  const [labels, setLabels] = React.useState(null);

  const handleChangeLang = (lg) => {
    setLang(lg);
  };
  React.useEffect(() => {
    setLabels(langage[lang]);
  }, [lang]);

  React.useEffect(() => {
    if (data) {
      setLang(DEFAULT_LANGAGE);
    }
  }, [data]);
  return (
    <LangageContext.Provider
      value={{
        handleChangeLang,
        labels,
        lang,
        me: data,
      }}
    >
      {props.children}
    </LangageContext.Provider>
  );
};

const useTranslate = () => React.useContext(LangageContext);
export { LangageContext, LangProvider, useTranslate };
