import {
  MY_PROFILe,
  UPDATE_MY_PROFILE,
  UPDATE_MY_SETTING,
  ME,
} from "graphql/tags";
import { useQuery, useMutation } from "react-apollo";

export const useQueryMe = () => {
  const { data, loading } = useQuery(ME);
  return {
    data: data?.me,
    loading,
  };
};

export const useQueryProfile = () => {
  const { data, ...others } = useQuery(MY_PROFILe);
  return {
    data: data?.myStore,
    ...others,
  };
};
export const useMutationProfile = () => {
  const [updateMyStore] = useMutation(UPDATE_MY_PROFILE);
  function updateMyProfile({ input }) {
    return updateMyStore({
      variables: {
        input,
      },
      update: (cache, {}) => {},
    });
  }
  return { updateMyProfile };
};
export const useMutationSettings = () => {
  const [updateMyStoreSettings] = useMutation(UPDATE_MY_SETTING);
  function updateMyProfile({ input }) {
    return updateMyStoreSettings({
      variables: {
        input,
      },
      update: (cache, {}) => {},
    });
  }
  return { updateMyProfile };
};
