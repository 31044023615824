import React from "react";
import * as Icon from "react-feather";

const horizontalMenuConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["ADMIN"],
    navLink: "/",
  },
  {
    id: "projects",
    title: "Projets",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["ADMIN"],
    navLink: "/projets",
  },
];

export default horizontalMenuConfig;
