import Token from "./Token";
import lodash from "lodash";
export const stripTypenames = (value) => {
  if (Array.isArray(value)) {
    return value.map(stripTypenames);
  } else if (value !== null && typeof value === "object") {
    const newObject = {};
    for (const property in value) {
      if (
        property !== "__typename" &&
        property !== "lastBuildApk" &&
        property !== "lastBuildAab"
      ) {
        newObject[property] = stripTypenames(value[property]);
      }
    }
    return newObject;
  } else {
    return value;
  }
};
export const makeId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const removeFirstAndLast = (str) => {
  if (str && str.length > 0) {
    const tab = str.split("");
    const n = tab.filter((c) => c !== `"`);
    return n.join("");
  }
  return "";
};
export const isAdmin = () => Token.getRole() === "ADMIN";

export const isAuthentificated = () =>
  localStorage.getItem("token-suer") && Token.getRole() !== null;
export const uniqBy = (array, args) => {
  return array.reduce((acc, current) => {
    if (
      !acc.some((item) =>
        args.map((arg) => current?.[arg] === item?.[arg]).every(Boolean)
      )
    ) {
      acc.push(current);
    }
    return acc;
  }, []);
};
export const hasPermissions = (userPermissions, permissions) => {
  const has = lodash.intersection(userPermissions, permissions);

  return has.length > 0;
};
