import gql from "graphql-tag";

export const GET_PROJECT_TEMPLATE_BY_PROJECT_ID = gql`
  query projectTemplateByProjectId($project: String, $subStore: String) {
    projectTemplateByProjectId(project: $project, subStore: $subStore) {
      id
      project
      store
      subStore
      general {
        logoPng {
          type
          name
          extension
          value
          description
        }
        logoSvg {
          type
          name
          extension
          value
          description
        }
        primaryColor {
          type
          name
          extension
          value
          description
        }
      }
      webSite {
        logoPng {
          type
          name
          extension
          value
          description
        }
        logoSvg {
          type
          name
          extension
          value
          description
        }
        favico {
          type
          name
          extension
          value
          description
        }
      }
      dashboard {
        backgroundLoginImage {
          type
          name
          extension
          value
          description
        }
        foregroundLoginImage {
          type
          name
          extension
          value
          description
        }
        cssFile {
          type
          name
          extension
          value
          description
        }
        defaultImage {
          type
          name
          extension
          value
          description
        }
      }
      appClient {
        appMobile {
          logo {
            type
            name
            extension
            value
            description
          }
          splashScreen {
            type
            name
            extension
            value
            description
          }
          logoWhite {
            type
            name
            extension
            value
            description
          }
          menu {
            home {
              type
              name
              extension
              value
              description
            }
            categories {
              type
              name
              extension
              value
              description
            }
            stores {
              type
              name
              extension
              value
              description
            }
            orders {
              type
              name
              extension
              value
              description
            }
            cart {
              type
              name
              extension
              value
              description
            }
            profile {
              type
              name
              extension
              value
              description
            }
            pin {
              type
              name
              extension
              value
              description
            }
          }
          font {
            fontOne {
              type
              name
              extension
              value
              description
            }
            fontTow {
              type
              name
              extension
              value
              description
            }
            fontThree {
              type
              name
              extension
              value
              description
            }
            fontFour {
              type
              name
              extension
              value
              description
            }
          }
          googleJson {
            android {
              type
              name
              extension
              value
              description
            }
            ios {
              type
              name
              extension
              value
              description
            }
          }
          jsFiles {
            jsFileSvg {
              type
              name
              extension
              value
              description
            }
          }
          certifs {
            certifAndroid {
              type
              name
              extension
              value
              description
            }
            certifIos {
              type
              name
              extension
              value
              description
            }
          }
        }
        googlePlay {
          screens {
            type
            name
            extension
            value
            description
          }
          banner {
            type
            name
            extension
            value
            description
          }
          logo512 {
            type
            name
            extension
            value
            description
          }
          icLauncher {
            type
            name
            extension
            value
            description
          }
          icStat {
            type
            name
            extension
            value
            description
          }
          file {
            type
            name
            extension
            value
            description
          }
        }
        appStore {
          screensT1125 {
            type
            name
            extension
            value
            description
          }
          screensT1242 {
            type
            name
            extension
            value
            description
          }
          screens3 {
            type
            name
            extension
            value
            description
          }
          icLauncher {
            type
            name
            extension
            value
            description
          }
          icStat {
            type
            name
            extension
            value
            description
          }
          file {
            type
            name
            extension
            value
            description
          }
          descriptions {
            descriptionShort {
              type
              name
              extension
              value
              description
            }
            descriptionLong {
              type
              name
              extension
              value
              description
            }
            note {
              type
              name
              extension
              value
              description
            }
          }
          privacy {
            type
            name
            extension
            value
            description
          }
          marketingUrl {
            type
            name
            extension
            value
            description
          }
          developerSection {
            name {
              type
              name
              extension
              value
              description
            }
            lastName {
              type
              name
              extension
              value
              description
            }
            email {
              type
              name
              extension
              value
              description
            }
            password {
              type
              name
              extension
              value
              description
            }
            phone {
              type
              name
              extension
              value
              description
            }
          }
        }
      }
      appPro {
        appMobile {
          logo {
            type
            name
            extension
            value
            description
          }
          logoPng {
            type
            name
            extension
            value
            description
          }
          splashScreen {
            type
            name
            extension
            value
            description
          }
          menu {
            orders {
              type
              name
              extension
              value
              description
            }
            delivery {
              type
              name
              extension
              value
              description
            }
            drive {
              type
              name
              extension
              value
              description
            }
          }
          font {
            fontOne {
              type
              name
              extension
              value
              description
            }
            fontTow {
              type
              name
              extension
              value
              description
            }
            fontThree {
              type
              name
              extension
              value
              description
            }
            fontFour {
              type
              name
              extension
              value
              description
            }
          }
          googleJson {
            android {
              type
              name
              extension
              value
              description
            }
            ios {
              type
              name
              extension
              value
              description
            }
          }
          jsFiles {
            jsFileSvg {
              type
              name
              extension
              value
              description
            }
          }
          certifs {
            certifAndroid {
              type
              name
              extension
              value
              description
            }
            certifIos {
              type
              name
              extension
              value
              description
            }
          }
        }
        googlePlay {
          screens {
            type
            name
            extension
            value
            description
          }
          banner {
            type
            name
            extension
            value
            description
          }
          logo512 {
            type
            name
            extension
            value
            description
          }
          icLauncher {
            type
            name
            extension
            value
            description
          }
          icStat {
            type
            name
            extension
            value
            description
          }
          file {
            type
            name
            extension
            value
            description
          }
        }
        appStore {
          screensT1125 {
            type
            name
            extension
            value
            description
          }
          screensT1242 {
            type
            name
            extension
            value
            description
          }
          screens3 {
            type
            name
            extension
            value
            description
          }
          icLauncher {
            type
            name
            extension
            value
            description
          }
          icStat {
            type
            name
            extension
            value
            description
          }
          file {
            type
            name
            extension
            value
            description
          }
          descriptions {
            descriptionShort {
              type
              name
              extension
              value
              description
            }
            descriptionLong {
              type
              name
              extension
              value
              description
            }
            note {
              type
              name
              extension
              value
              description
            }
          }
          privacy {
            type
            name
            extension
            value
            description
          }
          marketingUrl {
            type
            name
            extension
            value
            description
          }
          developerSection {
            name {
              type
              name
              extension
              value
              description
            }
            lastName {
              type
              name
              extension
              value
              description
            }
            email {
              type
              name
              extension
              value
              description
            }
            password {
              type
              name
              extension
              value
              description
            }
            phone {
              type
              name
              extension
              value
              description
            }
          }
        }
      }
      email {
        logo {
          type
          name
          extension
          value
          description
        }
        emailIcons {
          type
          name
          extension
          value
          description
        }
      }
    }
  }
`;

export const ADD_TEMPLATE_PROJECT = gql`
  mutation addTemplateProject($input: ProjectTemplateInput) {
    addTemplateProject(input: $input) {
      id
    }
  }
`;

export const CHANGE_IMAGE_IN_ARRAY = gql`
  mutation changeImageInArrayTemplateProject(
    $id: ID
    $app: String
    $store: String
    $section: String
    $image: String
    $newImage: ItemTemplateInput
  ) {
    changeImageInArrayTemplateProject(
      id: $id
      app: $app
      store: $store
      section: $section
      image: $image
      newImage: $newImage
    )
  }
`;

export const DELETE_IMAGE_FROM_ARRAY = gql`
  mutation deleteImageFromArrayTemplateProject(
    $id: ID
    $app: String
    $store: String
    $section: String
    $image: String
  ) {
    deleteImageFromArrayTemplateProject(
      id: $id
      app: $app
      store: $store
      section: $section
      image: $image
    )
  }
`;

export const DELETE_IMAGE_FROM_TEMPLATE = gql`
  mutation deleteImageTemplateProject(
    $id: ID
    $app: String
    $store: String
    $section: String
    $subSection: String
  ) {
    deleteImageTemplateProject(
      id: $id
      app: $app
      store: $store
      section: $section
      subSection: $subSection
    )
  }
`;

export const ADD_TEMPLATE_STORE = gql`
  mutation addTemplateStore($input: ProjectTemplateInput) {
    addTemplateStore(input: $input) {
      id
    }
  }
`;

export const GET_ALL_IMAGES_AS_ZIP = gql`
  mutation GetZipOfAllImages(
    $input: ProjectTemplateInput
    $id: String
    $subProjects: Boolean
  ) {
    GetZipOfAllImages(input: $input, id: $id, subProjects: $subProjects)
  }
`;

export const UPDATE_TEMPLATE_PROJECT = gql`
  mutation updateTemplateProject(
    $input: ProjectTemplateInput
    $id: String
    $subProjects: Boolean
  ) {
    updateTemplateProject(input: $input, id: $id, subProjects: $subProjects) {
      id
    }
  }
`;

export const DUPLICATE_TEMPLATE_PROJECT = gql`
  mutation duplicateTemplateProject(
    $id: String
    $duplicatedProjectName: String
  ) {
    duplicateTemplateProject(
      id: $id
      duplicatedProjectName: $duplicatedProjectName
    ) {
      id
    }
  }
`;
