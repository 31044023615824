import React from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Spinner,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";
import classnames from "classnames";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import { history } from "../../../history";

import Token from "configs/Token";
import { useTranslate } from "utility/context/Language";
import ReactCountryFlag from "react-country-flag";
import { LANGUAGES } from "configs/constants";
import {
  useNotificationMutation,
  useNotificationsQuery,
} from "graphql/tags/notification.tag";
import moment from "moment";

const NotificationItem = ({ title, content, type, createdAt }) => (
  <div className="d-flex justify-content-between">
    <Media className="d-flex align-items-start">
      <Media left href="#">
        <Icon.PlusSquare className={`font-medium-5 `} size={21} />
      </Media>
      <Media body>
        <Media
          heading
          className={`${type?.toLowerCase()} media-heading`}
          tag="h6"
        >
          {title}
        </Media>
        <p className="notification-text">{content}</p>
      </Media>
      <small>
        <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00">
          {moment(createdAt).fromNow()}
        </time>
      </small>
    </Media>
  </div>
);

const NotificationsContainer = () => {
  const { data, loading, refetch } = useNotificationsQuery();
  const { delelteAllNotificaion } = useNotificationMutation();
  React.useEffect(() => {}, [data, loading]);
  if (loading && !data) return <Spinner size="sm" />;
  function deleteAll() {
    delelteAllNotificaion().then((h) => {
      refetch();
    });
  }
  return (
    <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
      <DropdownToggle tag="a" className="nav-link nav-link-label">
        <Icon.Bell size={21} />
        <Badge pill color="primary" className="badge-up">
          {data?.count}
        </Badge>
      </DropdownToggle>
      <DropdownMenu tag="ul" right className="dropdown-menu-media">
        <li className="dropdown-menu-header">
          <div className="dropdown-header mt-0">
            <h3 className="text-white">{data?.count} New</h3>
            <span className="notification-title">App Notifications</span>
          </div>
        </li>
        <PerfectScrollbar
          className="media-list overflow-hidden position-relative"
          options={{
            wheelPropagation: false,
          }}
        >
          {data?.nodes?.map(NotificationItem)}
        </PerfectScrollbar>
        <li className="dropdown-menu-footer" onClick={deleteAll}>
          <DropdownItem tag="a" className="p-1 text-center">
            <span className="align-middle">Read all notifications</span>
          </DropdownItem>
        </li>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
const Langage = () => {
  const { lang, handleChangeLang } = useTranslate();
  return (
    <UncontrolledDropdown tag="li" className="nav-item">
      <DropdownToggle tag="a" className="nav-link">
        <div className="user-nav d-sm-flex d-none">
          <ReactCountryFlag
            countryCode={LANGUAGES.find((lg) => lg.lang === lang)?.flag}
          />
        </div>
      </DropdownToggle>
      <DropdownMenu right>
        {LANGUAGES.map((lg) => (
          <DropdownItem
            key={lg.lang}
            tag="a"
            href="#"
            onClick={() => handleChangeLang(lg.lang)}
          >
            <ReactCountryFlag countryCode={lg.flag} />
            <span className="align-middle">{lg.langage}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const UserDropdown = (props) => {
  function logout() {
    localStorage.removeItem("token-suer");
    history.push("/login");
    window.location.reload();
  }
  React.useEffect(() => {}, [props]);
  const data = {};
  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle tag="a" className="nav-link dropdown-user-link">
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name text-bold-600">{data?.name}</span>
          <span className="user-status">Actif</span>
        </div>
        <span data-tour="user">
          <img
            src={props.userImg}
            className="round"
            height="40"
            width="40"
            alt="avatar"
          />
        </span>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          tag="a"
          href="#"
          onClick={(e) => history.push("/account")}
        >
          <Icon.User size={14} className="mr-50" />
          <span className="align-middle">Edit Profile</span>
        </DropdownItem>

        <DropdownItem tag="a" href="#">
          <Icon.MessageSquare size={14} className="mr-50" />
          <span className="align-middle">Chats</span>
        </DropdownItem>

        <DropdownItem divider />
        <DropdownItem tag="a" href="#" onClick={(e) => logout()}>
          <Icon.Power size={14} className="mr-50" />
          <span className="align-middle">Log Out</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const NavbarUser = (props) => {
  const [navbarSearch, setNavBarSearch] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);

  const handleNavbarSearch = () => {
    setNavBarSearch(!navbarSearch);
  };

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <Langage />

      <NavItem className="nav-search" onClick={handleNavbarSearch}>
        <NavLink className="nav-link-search">
          <Icon.Search size={21} data-tour="search" />
        </NavLink>
        <div
          className={classnames("search-input", {
            open: navbarSearch,
            "d-none": navbarSearch === false,
          })}
        >
          <div className="search-input-icon">
            <Icon.Search size={17} className="primary" />
          </div>
          <Autocomplete
            className="form-control"
            suggestions={suggestions}
            filterKey="title"
            filterHeaderKey="groupTitle"
            grouped={true}
            placeholder="Explore Vuexy..."
            autoFocus={true}
            clearInput={navbarSearch}
            externalClick={(e) => {
              setNavBarSearch(false);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 27 || e.keyCode === 13) {
                setNavBarSearch(false);
                props.handleAppOverlay("");
              }
            }}
            customRender={(
              item,
              i,
              filteredData,
              activeSuggestion,
              onSuggestionItemClick,
              onSuggestionItemHover
            ) => {
              const IconTag = Icon[item.icon ? item.icon : "X"];
              return (
                <li
                  className={classnames("suggestion-item", {
                    active: filteredData.indexOf(item) === activeSuggestion,
                  })}
                  key={i}
                  onClick={(e) => onSuggestionItemClick(item.link, e)}
                  onMouseEnter={() =>
                    onSuggestionItemHover(filteredData.indexOf(item))
                  }
                >
                  <div
                    className={classnames({
                      "d-flex justify-content-between align-items-center":
                        item.file || item.img,
                    })}
                  >
                    <div className="item-container d-flex">
                      {item.icon ? (
                        <IconTag size={17} />
                      ) : item.file ? (
                        <img
                          src={item.file}
                          height="36"
                          width="28"
                          alt={item.title}
                        />
                      ) : item.img ? (
                        <img
                          className="rounded-circle mt-25"
                          src={item.img}
                          height="28"
                          width="28"
                          alt={item.title}
                        />
                      ) : null}
                      <div className="item-info ml-1">
                        <p className="align-middle mb-0">{item.title}</p>
                        {item.by || item.email ? (
                          <small className="text-muted">
                            {item.by ? item.by : item.email ? item.email : null}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    {item.size || item.date ? (
                      <div className="meta-container">
                        <small className="text-muted">
                          {item.size ? item.size : item.date ? item.date : null}
                        </small>
                      </div>
                    ) : null}
                  </div>
                </li>
              );
            }}
            onSuggestionsShown={(userInput) => {
              if (navbarSearch) {
                props.handleAppOverlay(userInput);
              }
            }}
          />
          <div className="search-input-close">
            <Icon.X
              size={24}
              onClick={(e) => {
                e.stopPropagation();
                setNavBarSearch(false);
                props.handleAppOverlay("");
              }}
            />
          </div>
        </div>
      </NavItem>
      <NotificationsContainer />
      <UserDropdown {...props} />
    </ul>
  );
};
export default NavbarUser;
