import gql from "graphql-tag";
import * as ReactAppolo from "react-apollo";
const GET_NOTIFICATION = gql`
  query getNotifications($skip: Int, $limit: Int) {
    getNotifications(skip: $skip, limit: $limit) {
      count
      nodes {
        title
        content
        createdAt
        type
      }
    }
  }
`;
const DELETE_ALL = gql`
  mutation {
    deleteAllNotifications
  }
`;
export const useNotificationsQuery = () => {
  const { data, ...rest } = ReactAppolo.useQuery(GET_NOTIFICATION, {
    variables: {},
  });
  return {
    data: data?.getNotifications,
    ...rest,
  };
};
export const useNotificationMutation = () => {
  const [deleteAll] = ReactAppolo.useMutation(DELETE_ALL);
  function delelteAllNotificaion() {
    return deleteAll({});
  }
  return {
    delelteAllNotificaion,
  };
};
