import gql from "graphql-tag";
const PROJECT_FRAGMENT = `
      id
      name
      appIdentifier
      appId
      protocol
      wsProtocol
      host
      wsEndpoint
      googleApiKey
      stripeTokenURL
      stripePublicAppKey
      oneSignalClientAppId
      databaseName
      jwtSecret
      jwtExpiresIn
      authEndpoint
      mailEndpoint
      stuartEndpoint
      visitorEndpoint
      graphqlEndpoint
      mediaEndpoint
      stripeEndpoint
      deployed
      uplaodPath
      maxNumberDays
      onesignalClientRestApiKey
      onesignalProRestApiKey
      oneSignalProAppId
      stripeAuthorizeURI
      stripeTokenURI
      stripeSecretAppKey
      stripeClientId
      publicApiURL
      port
      serverAddressIP
      sshUsername
      sshPassword
      apiPWD
    
      responsable {
        name
        phone
        email
      }
      entreprise {
        name
        siret
        url
        address {
          address
        }
        type
      }
      app
      doc
      description
      projectTheme

      appleId
      teamName
      teamId
      itcTeamId
      keyId
      issuerId

      authorizeStuart
      authorizeYper
      isMarketPlace
      authorizeStripeConnect
      authorizeCreateProductStore
      authorizeEditPriceImportedNotDuplicatedProduct
      authorizeSpecificSettings
      authorizeMenageParticular
      authorizeMenageFeeStripe
      authorizeMenageFeeService
      authorizePaymee
      authorizeAddons
      authorizeOptions
      showTitleCategory
      authorizeStoreDiscount
      authorizeStoreMarketing
      authorizeApkLink
      authorizeChangeProductQuantity
      showSmallLeftStoreImageInList
      showListProductsInTwoColumns
      authorizeGroup
      showEan
      showRef
      updateOrderPriceWhenUpdateOrderProducts
      uniqueStore
      authorizeVerifyInscription
      authorizeCopyProductOnImport
      authorizeDuplicateProducts
      authorizeUpdateMentionLegal
      authorizeShowGroupsInClientApp
      authorizeShowCommercantCode
      authorizeDeliveryMenModule
      authorizeShowTypesInClientApp
      authorizePrinter
      authorizeAuthentificationFacebook
      authorizeBannerAdsForStore
      authorizeChatDirect
      authorizeDeleteSubCommentNotice
      authorizeDeleteNotice
      authorizeNoticeStore
      authorizeLegalAge
      showHeaderAddressInApp
      authorizeDiagnostic
      authorizeIbanPayment
      authorizeDeliveryTimeInApp
      showAboutSectionInApp
      authorizeLoyaltyProgram
      stripeFeeFromStore
      stripeFeePourcent
      appDomain
      pm2Pid
      hostSuperAdmin
      mongooseUriConnection
      hooksEndpoint
      dashPWD
      subProjects {
        id
        name
        project
        subProjectId
        app
        folderName
        fontName
        isGroup
        appIdentifier
        isBuilding
        projectTheme
        appMobileVersion {
          androidBuild
          ios
          googlePlayPush
        }
        appPublished{
          appClient{
            appStore
            playStore
          }
        }
        lastBuildApk
        lastBuildAab
      }
      backendGitRepo
      dashboardGitRepo
      dashboardUrl
      appMobilePWD
      appProPWD
      appIdPro
      fontName
      authorizeTags
      authorizeStoreLabels
      appPublished{
        appClient{
          appStore
          playStore
        }
        appPro{
          appStore
          playStore
        }
      }

`;
//
export const GET_PROJECTS_TO_DEPLOY = gql`
  query projects($filter: ProjectQuery) {
    projects(filter: $filter) {
      id
      apiVersion
      dashboardVersion

      appMobileVersion {
        androidBuild
        ios
        googlePlayPush
      }
      appProVersion {
        androidBuild
        ios
        googlePlayPush
      }
      name
      appId
      protocol
      wsProtocol
      host
      deployed
      appDomain
      serverStatus
      description
      authorizeStuart
      authorizeYper
      isMarketPlace
      isLastUpdate
      isLastUpdateDashboard
      isLastUpdateAppMobile
      isLastUpdateAppPro
      dashboardUrl
      dashboardVersion
      isBuilding
      isBuildingAppPro
      appPublished {
        appClient {
          appStore
          playStore
        }
        appPro {
          appStore
          playStore
        }
      }
      subProjects {
        id
        name
        project
        subProjectId
        app
        folderName
        fontName
        isGroup
        appMobileVersion {
          androidBuild
          ios
          googlePlayPush
        }
        appPublished {
          appClient {
            appStore
            playStore
          }
        }
        isBuilding
        projectTheme

        lastBuildApk
        lastBuildAab
      }
      lastBuildApkPro
      lastBuildAabPro
      lastBuildApk
      lastBuildAab
    }
  }
`;

export const GET_PROJECTS = gql`
  query projects($filter: ProjectQuery) {
    projects(filter: $filter) {
      id
      apiVersion
      dashboardVersion
      appMobileVersion {
        androidBuild
        ios
        googlePlayPush
      }
      appProVersion {
        androidBuild
        ios
        googlePlayPush
      }
      appPublished {
        appClient {
          appStore
          playStore
        }
      }
      name
      appId
      protocol
      wsProtocol
      host
      deployed
      appDomain
      serverStatus
      description
      authorizeStuart
      authorizeYper
      isMarketPlace
      isLastUpdate
      isLastUpdateDashboard
      isLastUpdateAppMobile
      isLastUpdateAppPro
      dashboardUrl
      dashboardVersion
      isBuilding
      isBuildingAppPro
      lastBuildApkPro
      lastBuildAabPro
      lastBuildApk
      lastBuildAab
      subProjects {
        id
        name
        project
        subProjectId
        app
        folderName
        fontName
        isGroup

        isBuilding
        projectTheme
        lastBuildApk
        lastBuildAab
      }
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query ($id: ID!) {
    project(id: $id) {
     ${PROJECT_FRAGMENT}
    }
  }
`;
export const CHECK_APK_ON_MACSERVER = gql`
  mutation checkApkOnMacServer(
    $id: String
    $subProject: String
    $type: String
  ) {
    checkApkOnMacServer(id: $id, subProject: $subProject, type: $type)
  }
`;
export const CHECK_AAB_ON_MACSERVER = gql`
  mutation checkAabOnMacServer(
    $id: String
    $subProject: String
    $type: String
  ) {
    checkAabOnMacServer(id: $id, subProject: $subProject, type: $type)
  }
`;
export const STOP_APK_AAB_BUILD = gql`
  mutation stopApkAabBuild($id: String, $subProject: String, $type: String) {
    stopApkAabBuild(id: $id, subProject: $subProject, type: $type)
  }
`;

export const ADD_PROJECT = gql`
  mutation addProject($input: ProjectInput) {
    addProject(input: $input) {
      ${PROJECT_FRAGMENT}
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($input: ProjectInput) {
    updateProject(input: $input) {
     ${PROJECT_FRAGMENT}
    }
  }
`;

export const DELETE_PROJCT = gql`
  mutation deleteProject($id: ID) {
    deleteProject(id: $id) {
     ${PROJECT_FRAGMENT}
    }
  }
`;
export const DEPLOY_PROJECT = gql`
  mutation deployProject($id: String, $options: DeployOptionInput) {
    deployProject(id: $id, options: $options)
  }
`;
export const DEPLOY_DASHBOARD = gql`
  mutation deployDashBoard($id: String, $options: DeployOptionInput) {
    deployDashBoard(id: $id, options: $options)
  }
`;
export const DEPLOY_APP_MOBILE = gql`
  mutation deployAndroidApp($id: String, $subProject: String, $type: String) {
    deployAndroidApp(id: $id, subProject: $subProject, type: $type)
  }
`;
export const DEPLOY_APP_PRO = gql`
  mutation deployAndroidProApp(
    $id: String
    $subProject: String
    $type: String
  ) {
    deployAndroidProApp(id: $id, subProject: $subProject, type: $type)
  }
`;
export const DEPLOYED_PROJECT = gql`
  subscription {
    projectDeployed
  }
`;
export const ERROR_DEPLYED_PROJECT = gql`
  subscription {
    errorDeployed
  }
`;
export const DOWNLOAD_APK = gql`
  mutation downloadApk($id: String, $subProject: String, $type: String) {
    downloadApk(id: $id, subProject: $subProject, type: $type)
  }
`;
export const DOWNLOAD_AAB = gql`
  mutation downloadAab($id: String, $subProject: String, $type: String) {
    downloadAab(id: $id, subProject: $subProject, type: $type)
  }
`;
export const CREATE_SCHEDULE_DEPLOY = gql`
  mutation createScheduledDeploy($input: ScheduleInput) {
    createScheduledDeploy(input: $input) {
      id
    }
  }
`;
export const DELETE_SCHEDULE = gql`
  mutation deleteSchedule($id: String) {
    deleteSchedule(id: $id)
  }
`;
export const GET_ALL_SCHEDULE = gql`
  query {
    getSchedules {
      id
      date
      finished
      inProgress
      apis
      dashboards
      clients {
        project
        subProject
        androidBuild
        googlePlayPush
        ios
      }
      pros {
        project
        androidBuild
        googlePlayPush
        ios
      }
    }
  }
`;
export const DEPLOY_PROJECTS = gql`
  mutation deployProjects($projects: [String], $options: DeployOptionInput) {
    deployProjects(projects: $projects, options: $options)
  }
`;
export const UPDATE_BASE_API = gql`
  mutation {
    updateBaseApi
  }
`;
export const SETTINGS_QUERY = gql`
  query {
    getSettings {
      apiVersion
      dashboardVersion
      appMobileVersion
      appProVersion
    }
  }
`;
export const UPDATE_MOBILE_CONFIG = gql`
  mutation updateMobileConfigProject($input: ProjectInput) {
    updateMobileConfigProject(input: $input) {
      id
    }
  }
`;
export const VERIFY_BEFORE_DEPLOY = gql`
  mutation verifyBeforeDeploy($apps: [AppVerifyInput]) {
    verifyBeforeDeploy(apps: $apps) {
      valid
      invalidAppMobile {
        appType
        id
        subProject
      }
    }
  }
`;
export const GENERATE_ENV_FILE = gql`
  mutation generateEnvFile($id: String) {
    generateEnvFile(id: $id)
  }
`;
export const GET_ENV_FILE = gql`
  query getEnvFileContent($id: String) {
    getEnvFileContent(id: $id)
  }
`;
export const GET_APP_INFO = gql`
  query getAppInfos(
    $id: String
    $subProject: String
    $appType: APPLICATION_TYPE
  ) {
    getAppInfos(id: $id, subProject: $subProject, appType: $appType) {
      valid
      files {
        name
        valid
      }
    }
  }
`;
